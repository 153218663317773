<template>
  <div v-if="loading" class="flex">
    <svg
      class="h-32 w-96 m-auto"
      version="1.1"
      id="L5"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 50 100"
      enable-background="new 0 0 0 0"
      xml:space="preserve"
    >
      <circle fill="#ffac3c" stroke="none" cx="0" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 15 ; 0 -15; 0 15"
          repeatCount="indefinite"
          begin="0"
        />
      </circle>
      <circle fill="#14e0d2" stroke="none" cx="24" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 15 ; 0 -15; 0 15"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill="#fede84" stroke="none" cx="48" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 15 ; 0 -15; 0 15"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: "LoadingComp",
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    // type: {
    //   type: String,
    //   default: "normal",
    // },
  },
};
</script>
